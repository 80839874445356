@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global CSS file, e.g., index.css */
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #610b0c;
  border-radius: 20px;
  border: 3px solid #f1f1f1;
}

@layer utilities {
  .underline-animation::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width 0.5s ease-in-out; /* Slower and smoother animation */
  }

  .underline-animation:hover::after {
    width: 100%;
  }
}
